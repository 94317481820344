'use client'
import Script from "next/script"

const OrganizationLdJson = ({  }) => {

    let jsonLd = [{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "PropertySpace - Pattaya Real Estate",
      "url": "https://propertyspace.com",
      "logo": "https://propertyspace.com/logo.png",
      "description": "Real Estate Agency in Pattaya",
      "address": "24/56 Moo 5 Nongprue, Pattaya, Chonburi, Thailand, 20150",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+66-89-714-1551",
        "contactType": "Customer Service",
        "areaServed": "TH",
        "availableLanguage": "English, Thai, Chinese"
      },
      "sameAs": [
        "https://www.facebook.com/Propertyspace.co.th",
        "https://www.instagram.com/propertyspace.co.th/",
        "https://www.tiktok.com/@propertyspace.th",
        "https://www.youtube.com/@PattayaPropertySpace"
      ]
    },
    {
      "@context": "http://schema.org",
      "@type": "RealEstateAgent",
      "name": "PropertySpace - Pattaya Real Estate",
      "image": "https://propertyspace.com/logo.png",
      "url": "https://propertyspace.com",
      "slogan": "Discover Your Perfect Space in Pattaya Real Estate",
      "telephone": "+66-89-714-1551",
      "email": "info@propertyspace.co.th",
      "priceRange": "$$$.",
      "address": "24/56 Moo 5 Nongprue, Pattaya, Chonburi, Thailand, 20150"
  }
  ]
  
    return (
        <Script
            strategy="beforeInteractive"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
            key="organization-jsonld"
        />
    )
}

export default OrganizationLdJson