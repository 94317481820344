import(/* webpackMode: "eager" */ "/propertyspace/app/globals.css");
import(/* webpackMode: "eager" */ "/propertyspace/components/LdJson/OrganizationLdJson.js");
import(/* webpackMode: "eager" */ "/propertyspace/components/LinkComponent.js");
import(/* webpackMode: "eager" */ "/propertyspace/components/Nav.js");
import(/* webpackMode: "eager" */ "/propertyspace/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/propertyspace/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/propertyspace/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/propertyspace/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/propertyspace/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/propertyspace/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/propertyspace/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Oxygen\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-prompt\",\"weight\":\"300\"}],\"variableName\":\"poppins\"}")